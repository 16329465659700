import React from 'react';
import './international.css';
import { Link } from 'react-router-dom';
import Logo from "../../images/logo-footer.png";
import { MetaTags } from 'react-meta-tags';

const international = () => {

  // useLayoutEffect(() => {
  //   window.scrollTo(0, 0)
  // });


  return (
    <div>
      <MetaTags>
        <title>Eye Treatment In Khar Mumbai | Eye Life Hospital</title>
        <meta title="Eye Treatment In Khar Mumbai | Eye Life Hospital" />
        <meta
          name="description"
          content="Restore Clarity and Vision with Superior Eye Treatment in Khar, Mumbai. Trust the Experts & Contact Us Now To Book Appointment"
        />
        <meta
          name="keywords"
          content="Best Eye Hospital In Khar Mumbai,Cataract surgery In Khar Mumbai,LASIK Surgery in Khar Mumbai,Retina Surgery in Khar Mumbai,Cornea Specialist in Khar Mumbai,Best Eye Doctor in Khar Mumbai,Laser Eye Surgery In Khar Mumbai "
        />
        <link rel="canonical" href="https://eyelifehospital.com/eye-treatment-in-khar-mumbai" />
        <meta http-equiv="cache-control" content="no-cache" />
        <meta http-equiv="expires" content="0" />
        <meta http-equiv="pragma" content="no-cache" />
        <meta property="og:title" content="Eye Treatment In Khar Mumbai | Eye Life Hospital" />
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://eyelifehospital.com/" />
        <meta property="og:description" content="Restore Clarity and Vision with Superior Eye Treatment in Khar, Mumbai. Trust the Experts & Contact Us Now To Book Appointment" />
        <meta property="og:image" content="https://eyelifehospital.com/static/media/logo-footer.0798714161edb8b2d5b9.png" />
      </MetaTags>
      {/* Home banner */}
      <div className='internationalHome'>
        <div className='eyelife-Logo'>
          <img src={Logo} alt='eye Logo' />
        </div>
      </div>

      {/* Pointers */}
      <div className="pointersSection">
        <h4 className='international-category'>Surgical procedures</h4>
        <h6><Link to="/retina-surgery-in-khar-mumbai">Retina surgery</Link> </h6>
        <p>Retinal detachment, Diabetic retinopathy, Macular Hole surgery, Giant retinal tear surgery, Epi Retinal Membrane peeling, Recalcitrant Macular Edema, Vitreous Haemorrhage</p>

        <h6><Link to="/cornea-specialist-in-khar-mumbai">Corneal transplantation</Link></h6>
        <p>DSAEK, DMEK, DALK, PK, Boston Keratoprosthesis</p>

        <h6><Link to="/keratoconus-treatment-in-khar-mumbai">Keratoconus treatment</Link></h6>
        <p>Collagen cross linking, TREK, TPRK, INTACS</p>

        <h6><Link to="/lasik-surgery-in-khar-mumbai">Refractive Surgery</Link></h6>
        <p>Lasik, Femto LASIK(blade-less), SMILE, PRK / Surface ablation, ICL</p>

        <h6><Link to="/dry-eye-treatment-in-khar-mumbai">Ocular surface reconstruction </Link></h6>
        <p>Pterygium, Mucous membrane grafting, Amniotic membrane grafting, Tenonplasty </p>

        <h6><Link to="/glaucoma-specialist-in-khar-mumbai">Glaucoma surgery</Link></h6>
        <p>Trabeculectomy, Valve implant, Stent Surgery</p>

        <h6><Link to="/eye-injury-treatment-in-khar-mumbai">Anterior segment reconstruction</Link></h6>
        <p>Pupilloplasty, Iridoplasty, IOL repositioning, Scleral fixation IOL</p>

        <h6><Link to="/eye-injury-treatment-in-khar-mumbai">Ocular trauma management</Link></h6>
        <p>Corneal tear repair, Scleral tear repair, Foreign body removal etc</p>
      </div>

      <div className="pointersSection1">

        <h2 class="international-category">OPD investigations & procedures</h2>
        <h6> <span><i class="fa fa-globe" aria-hidden="true"></i></span> <Link to="/cornea-specialist-in-khar-mumbai">Scheimpflug Topography</Link></h6>
        <h6> <span><i class="fa fa-globe" aria-hidden="true"></i></span><Link to="/retina-surgery-in-khar-mumbai"> OCT – Optical Coherence Tomography </Link></h6>
        <h6> <span><i class="fa fa-globe" aria-hidden="true"></i></span><Link to="/cataract-surgery-in-khar-mumbai"> Optical Biometer </Link></h6>
        <h6> <span><i class="fa fa-globe" aria-hidden="true"></i></span><Link to="/dry-eye-treatment-in-khar-mumbai"> Meibomian gland analysis </Link></h6>
        <h6> <span><i class="fa fa-globe" aria-hidden="true"></i></span><Link to="/glaucoma-specialist-in-khar-mumbai"> Visual field testing </Link></h6>
        <h6> <span><i class="fa fa-globe" aria-hidden="true"></i></span><Link to="/glaucoma-specialist-in-khar-mumbai"> Double frequency Nd-YAG laser </Link></h6>
        <h6> <span><i class="fa fa-globe" aria-hidden="true"></i></span><Link to="/dry-eye-treatment-in-khar-mumbai"> IPL – Intense Pulse Light treatment </Link></h6>
        <h6> <span><i class="fa fa-globe" aria-hidden="true"></i></span><Link to="/dry-eye-treatment-in-khar-mumbai"> Dry Eye Workup </Link></h6>
        <h6> <span><i class="fa fa-globe" aria-hidden="true"></i></span><Link to="/glaucoma-specialist-in-khar-mumbai"> Glaucoma Workup </Link></h6>
        <h6> <span><i class="fa fa-globe" aria-hidden="true"></i></span><Link to="/cataract-surgery-in-khar-mumbai"> Yag Capsulotomy </Link></h6>
        <h6> <span><i class="fa fa-globe" aria-hidden="true"></i></span><Link to="/glaucoma-specialist-in-khar-mumbai"> Yag Iridotomy </Link></h6>
        <h6> <span><i class="fa fa-globe" aria-hidden="true"></i></span><Link to="/cornea-specialist-in-khar-mumbai"> Microscopy – Eye lash, Corneal ulcer treatment </Link></h6>
        <h6> <span><i class="fa fa-globe" aria-hidden="true"></i></span><Link to="/glaucoma-specialist-in-khar-mumbai"> SLT Laser </Link></h6>
        <h6> <span><i class="fa fa-globe" aria-hidden="true"></i></span><Link to="/retina-surgery-in-khar-mumbai"> Macula Laser  </Link></h6>
        <h6> <span><i class="fa fa-globe" aria-hidden="true"></i></span><Link to="/retina-surgery-in-khar-mumbai"> Retina Laser </Link></h6>
        <h6> <span><i class="fa fa-globe" aria-hidden="true"></i></span><Link to="/retina-surgery-in-khar-mumbai"> Barrage Retina Laser </Link></h6>

      </div>

    </div>
  )
}

export default international